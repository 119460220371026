body {
    background-color: #f8fbfd;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    cursor: pointer;
}

button {
    background-color: transparent;
}
